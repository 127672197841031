<template>
  <div>
    <v-main>
      <v-app>
        <navbar />
        <router-view />
      </v-app>
    </v-main>
  </div>
</template>

<script>
import navbar from "./components/navbar.vue";
export default {
  name: "App",
  components: {
    navbar,
  },
};
</script>

<style lang="scss">
</style>
