<template>
  <div>
    <v-app-bar color="#C05545" fixed elevate-on-scroll>
      <v-spacer></v-spacer>
      <v-toolbar-items v-for="view in views" :key="view.name">
        <v-btn :to="view.link" color="#C05545" elevation="0">
          <v-icon>
            {{ view.icon }}
          </v-icon>
          {{ view.name }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "navbar",
  data() {
    return {
      views: [
        { name: "Home", link: "/", icon: "mdi-home" },
        { name: "Calculator", link: "/calc", icon: "mdi-calculator" },
        { name: "About", link: "/about", icon: "mdi-account" },
      ],
    };
  },
};
</script>