<template>
  <div>
    <v-img
      class="background"
      src="https://images.unsplash.com/photo-1551158423-100d177bfaae?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
    >
    </v-img>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
.background {
  height: 100vh;
}
</style>
