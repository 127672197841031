<template>
  <div>
    <v-img
      class="background"
      src="https://images.unsplash.com/photo-1449824913935-59a10b8d2000?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
    >
      <v-container class="mt-16">
        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn
              href="https://www.instagram.com/timo.kat/"
              height="90"
              width="200"
            >
              <v-icon color="#C05545" class="pr-2">mdi-instagram</v-icon>
              <v-col>Instagram </v-col>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-col class="d-flex justify-center">
        <v-btn
          href="https://open.spotify.com/user/tc3cgslentqdt7lfbpmwindas?si=c2b7cd02a0334ac9"
          height="90"
          width="200"
        >
          <v-icon color="#C05545" class="pr-2">mdi-spotify</v-icon>
          <v-col>Spotify</v-col>
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-btn
          href="https://steamcommunity.com/id/timiboy1000/"
          height="90"
          width="200"
        >
          <v-icon color="#C05545" class="pr-2">mdi-steam</v-icon>
          <v-col>Steam</v-col>
        </v-btn>
      </v-col>
    </v-img>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped>
.background {
  height: 100vh;
}
</style>