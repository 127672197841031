<template>
  <div>
    <v-img
      class="background"
      src="https://images.unsplash.com/photo-1572297880316-e7c2d45be117?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80"
    >
      <v-container class="mt-16">
        <v-card class="mt-16 pb-8" color="#C05545">
          <v-row>
            <v-col>
              <v-text-field
                class="pl-8"
                color="yellow"
                v-model="zahl1"
                type="number"
                label="Zahl 1"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                class="pr-8"
                color="yellow"
                v-model="zahl2"
                type="number"
                label="Zahl 2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="yellow" @click="addieren">+</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="yellow" @click="subtrahieren">-</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="yellow" @click="multiplizieren">x</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="yellow" @click="dividieren">:</v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-col>
            <v-col>Ergebnis: {{ ergebnis }} </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-img>
  </div>
</template>

<script>
export default {
  name: "Calc",
  data() {
    return {
      zahl1: "",
      zahl2: "",
      ergebnis: "",
    };
  },
  methods: {
    addieren: function () {
      this.ergebnis = parseFloat(this.zahl1) + parseFloat(this.zahl2);
    },
    subtrahieren: function () {
      this.ergebnis = parseFloat(this.zahl1) - parseFloat(this.zahl2);
    },
    multiplizieren: function () {
      this.ergebnis = parseFloat(this.zahl1) * parseFloat(this.zahl2);
    },
    dividieren: function () {
      this.ergebnis = parseFloat(this.zahl1) / parseFloat(this.zahl2);
    },
  },
};
</script>

<style scoped>
.background {
  height: 100vh;
}
</style>